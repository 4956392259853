<template>
	<v-card flat class="rounded-xl">
		<v-card-subtitle>
			{{ $t("title") }}
			<a
				href="https://app.floriday.io/settings/apps/0oaq3honfbqj9brIF0h7"
				target="_blank"
			>
				https://app.floriday.io/settings/apps/0oaq3honfbqj9brIF0h7
			</a>
		</v-card-subtitle>

		<v-card-text>
			<v-form ref="form" onSubmit="return false;" v-model="isFormValid">
				<v-row
					:key="index"
					class="my-0 py-0"
					v-for="(item, index) in localSelectedCompanies"
				>
					<v-col cols="10" class="my-0 py-0">
						<v-text-field
							:label="$t('apiKey')"
							:rules="[rules.required]"
							outlined
							v-model="localSelectedCompanies[index]"
						></v-text-field>
					</v-col>
					<v-col cols="2" class="my-0 py-0">
						<v-btn @click="removeItem(index)" class="mt-2" icon>
							<v-icon color="error">close</v-icon>
						</v-btn>
					</v-col>
				</v-row>

				<v-btn @click="addItem()" rounded text
					>+ {{ $t("label.add") }}</v-btn
				>
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-spacer />
			<v-btn
				@click="back()"
				:class="['black--text', 'mr-2']"
				rounded
				small
				text
			>
				{{ $t("label.back") }}
			</v-btn>
			<v-btn
				@click="next()"
				:class="['white--text']"
				:color="'black'"
				:disabled="!isFormValid || localSelectedCompanies.length == 0"
				rounded
				small
			>
				{{ $t("label.next") }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import rules from "@/utils/rules.js";

export default {
	name: "floriday-stepper-content",
	props: {
		content: {
			type: Object,
			default: {},
		},
		getAuthCode: {
			type: Boolean,
			default: false,
		},
		selectedCompanies: {
			type: Array,
			default: [],
		},
		step: {
			type: Number,
			default: 1,
		},
		token: {
			type: Object,
			default: {},
		},
	},
	components: {},

	apollo: {},
	data: function () {
		return {
			isFormValid: false,
			localSelectedCompanies: this.selectedCompanies,
		};
	},
	computed: {
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {},
	created() {
		if (this.localSelectedCompanies.length == 0) {
			this.addItem();
		}

		this.rules = rules;
	},
	mounted() {},
	methods: {
		addItem() {
			this.localSelectedCompanies.push("");
		},
		removeItem(index) {
			this.localSelectedCompanies.splice(index, 1);
		},
		back() {
			this.$emit("update:step", this.step - 1);
		},

		next() {
			this.$emit("update:step", this.step + 1);
		},
	},
};
</script>

<i18n>
{
  "en": {
	"title": "Enter the API keys here for the companies you want to retrieve data from. For each company, you will need to add the LinkThings app in Floriday. This will provide you with the API key that you can fill in below. You can find the LinkThings Floriday app here: ",
	"apiKey": "API Key"
  },
  "nl": {
	"title": "Vul hier API Keys in van de bedrijven die je wilt uitlezen. Per bedrijf zul je de LinkThings app in Floriday moeten toevoegen, dit geeft je de API Key die je hieronder kunt vullen. De LinkThings Floriday app kun je hier vinden: ",
	"apiKey": "API Key"
  },
  "de": {
	"title": "Geben Sie hier die API-Schlüssel der Unternehmen ein, die Sie auslesen möchten. Für jedes Unternehmen müssen Sie die LinkThings-App in Floriday hinzufügen. Dies gibt Ihnen den API-Schlüssel, den Sie unten eingeben können. Die LinkThings Floriday-App finden Sie hier: ",
	"apiKey": "API Key"
  }
}
</i18n>
